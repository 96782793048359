import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../components/layout'
import BlockContent from "@sanity/block-content-to-react";
import { Helmet } from 'react-helmet'

export const query = graphql`
  query LocationTemplateQuery($slug: String!) {
    location: sanityLocalArea(slug: {current : {eq: $slug}}) {
      title
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      category {
        title
      }
      _rawDescription
    }
  }
`

const LocationTemplate = ({data}) => {

  const {category, title, image, _rawDescription } = data.location
  
  console.log(_rawDescription);

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'location-page' }} />
      <main className="main">
        <section className="container section section-first">
		      <div className="row justify-content-center">
  			    <header className="col-12 col-lg-12 col-xl-10 post-header">
  				    <div className="title title--overhead js-lines">{category.title}</div>
  				    <h1 className="title title--h2 js-lines">{title}</h1>
  				  </header>
  				  <figure className="col-12 post-main-image">
  			        <img className="cover lazyload" src={image.asset.fluid.src} alt="" />
  			    </figure>
  				  <div className="col-12 col-lg-10 col-xl-8">
              {_rawDescription && (<BlockContent blocks={_rawDescription} renderContainerOnSingleChild={true}/>)}
  			   </div>
  			   <div className="col-12 col-lg-10 col-xl-8 d-flex justify-content-center">
            <Link to="/#location" className="btn mt-4">Back to homepage</Link>
  			   </div>
    			</div>
    		</section>
	    </main>
    </Layout>
  )
}

export default LocationTemplate